import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
// import SettingsMenu from './SettingsMenu/SettingsMenu';
import DeviceSettingButton from './DeviceSettingButton';
import { Steps } from '../PreJoinScreens';
// import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
// import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import config from '../../../config.json';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    margin: '1em 0em',
    textAlign: 'center',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0.25em',
      borderTop: '1px solid #707578',
    },
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '33.33%',
    // border: '2px solid #aaa',
    margin: '1em 0em',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      border: '2px solid #aaa',
    },
  },
  localPreviewContainer: {
    // paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '340px',
      // padding: '0 2.5em',
    },
  },
  localPreviewContainer2: {
    // paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '92%',
      // padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      // display: 'flex',
      justifyContent: 'space-between',
      // margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    // padding: '0.8em 0',
    // margin: 0,
    fontSize: '16px',
    width: '100%',
    justifyContent: 'right',
  },
  endTypo: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    display: 'none',
    textAlign: 'center',
  },
  displayTime: {
    textAlign: 'center',
    fontSize: '24px',
  },
  displayTimeTitle: {
    marginTop: '0.5em',
    textAlign: 'center',
    fontSize: '16px',
  },
  buttonColor: {
    backgroundColor: '#dc3296',
    color: '#fbe9f3',
    fontSize: '24px',
    width: '300px',
    '&:hover': {
      backgroundColor: '#FF5ABE',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  setName: (name: string) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep, setName }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const [showTime, setShowTime] = useState('');
  // const [checkFlag, setCheckFlag] = useState(true);
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const isDoctor = window.sessionStorage.getItem('isDoctor') + '';
  var clinicName = config.Keys.find(o => o.id === roomName.substring(0, 4))?.Name;
  const time = new Date(
    parseInt(roomName.substring(20, 24)),
    parseInt(roomName.substring(24, 26)) - 1,
    parseInt(roomName.substring(26, 28)),
    parseInt(roomName.substring(28, 30)),
    parseInt(roomName.substring(30, 32))
  );
  useEffect(() => {
    getName();
  }, []);

  const [isCol, setIsCol] = useState(true);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < window.innerHeight) {
        setIsCol(true);
      } else {
        setIsCol(false);
      }
    }
    window.addEventListener('resize', handleResize);
  });
  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      setIsCol(true);
    } else {
      setIsCol(false);
    }
  }, []);
  const handleDisplayTime = () => {
    setTimeout(function() {
      let today = new Date();
      let totalSeconds = (time.getTime() - today.getTime()) / 1000;
      if (totalSeconds <= 0) {
        // if(true){
        setShowTime('00時間00分00秒');
        // setCheckFlag(false);
      } else {
        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        let seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
        setShowTime(hours + '時間' + minutes + '分' + seconds + '秒');
        handleDisplayTime();
      }
    }, 1000);
  };

  window.sessionStorage.setItem('roomName', roomName.substring(0, 19));
  const getName = () => {
    var key = config.Keys.find(o => o.id === roomName.substring(0, 4))?.AccessToken;
    var endPoint = config.GetName;
      fetch(endPoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        appname: 'ljn',
        prgname: 'api-chatname',
        arguments:
          '-A' +
          roomName.substring(0, 4) +
          key +
          ',-A' +
          roomName.substring(0, 4) +
          ',-A' +
          roomName.substring(4, 12) +
          ',-A' +
          roomName.substring(12, 19),
      }),
      redirect: 'follow',
    })
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        if (Object.keys(data).includes('doctor')) {
          if (isDoctor === '1') {
            if (data.doctor != '') {
              setName(data.doctor);
            } else {
              setName('ドクター');
            }
          } else {
            if (data.kranke != '') {
              setName(data.kranke);
            } else {
              setName('患者');
            }
          }
        } else if (Object.keys(data).includes('message')) {
          setName(data.message);
        } else {
          setName('Error');
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleJoin = () => {
    if (roomName.length > 19) {
      roomName = roomName.substring(0, 19);
    }
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" style={{ width: '100px', height: '100px', color: '#dc3296' }} />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            接続中
          </Typography>
        </div>
      </Grid>
    );
  }

  handleDisplayTime();
  return (
    <>
      {(isCol || window.innerWidth >= 900) && (
        <>
          <Typography variant="h5" className={classes.endTypo}>
            {clinicName}
          </Typography>
          <Typography variant="h5" className={classes.gutterBottom}>
            {/* {roomName.substr(0, 19)} に参加する */}
          </Typography>
        </>
      )}
      <Grid container justifyContent="center">
        <Grid item sm={12} xs={12}>
          <div className={isCol ? classes.localPreviewContainer : classes.localPreviewContainer2}>
            <LocalVideoPreview identity={name} isCol={isCol} />
          </div>
          <div className={classes.mobileButtonBar}>
            {/* <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
            <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
            <SettingsMenu mobileButtonClass={classes.mobileButton} /> */}
            <DeviceSettingButton />
          </div>
        </Grid>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
          <div className={classes.joinButtons}>
            {/* <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
                Cancel
              </Button> */}
            <Button
              variant="contained"
              // color="primary"
              data-cy-join-now
              onClick={handleJoin}
              disabled={disableButtons}
              className={classes.buttonColor}
            >
              今すぐ始める
            </Button>
          </div>
          <Typography variant="h5" className={classes.displayTimeTitle}>
            開始まで、あと <br />
          </Typography>
          <Typography variant="h5" className={classes.displayTime}>
            {showTime}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
