import React, { useState } from 'react';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';

import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SettingsIcon from '../../../icons/SettingsIcon';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function DeviceSettingButton() {
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setDeviceSettingsOpen(true)} startIcon={<SettingsIcon />}>
        オーディオ設定
      </Button>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
