import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import { useLocation, useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

// import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import EndCallDIalog from './EndCallDIalog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      fontSize: '16px',
      '&:hover': {
        background: '#1E3C96',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        width: '300px',
      },
    },
    buttonColor: {
      backgroundColor: '#1e3c96',
      color: '#fbe9f3',
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  // const { room } = useVideoContext();
  // const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  const [confirmEndCall, setConfirmEndCall] = useState(false);
  // const isDoctor = window.sessionStorage.getItem('isDoctor') + '';

  // const DisconnectRequest = () => {
  //   room!.disconnect();
  //   history.replace(location?.state?.from || { pathname: '/EndCall' });
  // };

  return (
    <>
      {/* <Button
      onClick={DisconnectRequest}
      className={clsx(classes.button, props.className, classes.buttonColor)}
      data-cy-disconnect
    >
      終了する
    </Button> */}
      <Button
        onClick={() => setConfirmEndCall(true)}
        className={clsx(classes.button, props.className, classes.buttonColor)}
      >
        終了する
      </Button>
      <EndCallDIalog
        open={confirmEndCall}
        onClose={() => {
          setConfirmEndCall(false);
        }}
      />
    </>
  );
}
