import React from 'react';

import { Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    paddingTop: '45vh',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '35vh',
    },
  },
}));

export default function ErrorPage() {
  const classes = useStyles();
  const { Error } = useParams();

  return (
    <IntroContainer>
      <Typography variant="h4" className={classes.gutterBottom}>
        {Error}
      </Typography>
    </IntroContainer>
  );
}
