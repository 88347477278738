import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { Theme, Link } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import Typography from '@material-ui/core/Typography';
import config from '../../config.json';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BTN_R_PAY_IMG_PATH } from '../../static/png';
import { BTN_PAY_PAY_PATH } from '../../static/png';

//#region declare const
declare const window: any;

const {
  REACT_APP_API_URL,
  REACT_APP_MERCHANT_ID,
  REACT_APP_PUBLIC_KEY_ID,
  REACT_APP_LEDGER_CURRENCY,
  REACT_APP_CHECKOUT_LANGUAGE,
  REACT_APP_SANDBOX,
} = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    margin: '1em 0em',
    textAlign: 'center',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      margin: '0.25em 0em 1.5em 0em',
      paddingTop: '3.5em',
      borderTop: '1px solid #707578',
    },
  },
  passcodeContainer: {
    minHeight: '120px',
  },
  joinButtons: {
    maxWidth: '300px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '4em',
    margin: '0 auto',
  },
  submitButton: {
    justifyContent: 'center',
    minWidth: '200px',
    backgroundColor: '#dc3296',
    color: 'white',
    fontSize: '20px',
    '&:hover': {
      backgroundColor: '#FF5ABE',
    },
    height: '60px',
    borderRadius: '10px',
  },
  paymentButton: {
    justifyContent: 'center',
    minWidth: '200px',
    color: 'white',
    fontSize: '24px',
    backgroundSize: '100%',
    backgroundRepeat: 'repeat-y',
    height: '60px',
    borderRadius: '10px',
  },
  paymentInformation: {
    whiteSpace: 'pre-wrap',
  },
  endTypo: {
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block',
    // },
    display: 'block',
    textAlign: 'center',
  },
  showPc: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    display: 'none',
  },
  gridStle: {
    margin: 'auto',
    padding: '10px',
  },
  tableClass: {
    width: '100%',
  },
  tdTitleClass: {
    border: '1px solid gray',
    textAlign: 'right',
    width: '60%',
    fontSize: '16px',
    backgroundColor: 'whitesmoke',
    padding: '0.5em',
  },
  tdPriceClass: {
    border: '1px solid gray',
    textAlign: 'right',
    width: '40%',
    fontSize: '16px',
    padding: '0.5em',
  },
  hidden: {
    display: 'none',
  },
  textAlignCenter: {
    textAlign: 'center',
    padding: '1em',
    fontSize: '20px',
  },
}));
//#endregion

export default function PaymentPage() {
  //#region component state
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [urlPaypay, setUrlPaypay] = useState('');
  const { Code } = useParams();
  const location = useLocation();
  const location1 = useLocation<{ from: Location }>();
  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [paymentTable, setpaymentTable] = useState([]);
  const [totalPayment, setTotalPaymentTable] = useState('');
  const amazonCheckoutSessionId = new URLSearchParams(location.search).get('amazonCheckoutSessionId');

  var info = '';
  //#endregion

  //#region payment function
  const GetPaymentLink = (total: string) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      OrderID: Code + '-cred',
      Amount: total,
      PayMethods: ['credit'],
      MemberID: '',
    });

    fetch(config.GetLink, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then(response => response.text())
      .then(result => {
        if (result != null) {
          setUrl(result);
        }
        // Hidden button Payment Credit . account gmo not existing
      })
      .catch(error => console.log('error', error));
  };

  const GetPaymentLinkPaypay = (total: string) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      OrderID: Code + '-payp',
      Amount: total,
      PayMethods: ['paypay'],
      MemberID: '',
    });

    fetch(config.GetLink, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then(response => response.text())
      .then(result => {
        if (result != null) {
          setUrlPaypay(result);
        }
      })
      .catch(error => console.log('error', error));
  };

  const PaymentHandler = () => {
    window.location.href = url;
  };

  const PaymentHandlerPaypay = () => {
    window.location.href = urlPaypay;
  };

  const initialAmazonPay = (amount: number) => {
    fetch(`${REACT_APP_API_URL}/api/amazonpay/generate-button-signature`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: amount, checkoutSessionId: Code }),
    })
      .then(response => response.json())
      .then(result => {
        if (window.amazon) {
          let amazon = window.amazon;
          amazon.Pay.renderButton('#AmazonPayButton', {
            // set checkout environment
            merchantId: REACT_APP_MERCHANT_ID,
            publicKeyId: REACT_APP_PUBLIC_KEY_ID,
            ledgerCurrency: REACT_APP_LEDGER_CURRENCY,
            // customize the buyer experience
            checkoutLanguage: REACT_APP_CHECKOUT_LANGUAGE,
            productType: 'PayOnly',
            placement: 'Checkout',
            buttonColor: 'Gold',
            sandbox: !REACT_APP_SANDBOX,
            // configure Create Checkout Session request
            createCheckoutSessionConfig: {
              payloadJSON: JSON.parse(result.payload), // payload generated in step 2
              signature: result.signature, // signature generatd in step 3
            },
          });
        }
      });
  };

  const FormatNumber = (a: string) => {
    var price = Number(a);
    var returnValue = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(price);
    return returnValue;
  };
  //#endregion

  useEffect(() => {
    if (Code) {
      setClinicName(config.Keys.find(o => o.id === Code.substring(0, 4))?.Name + '');
      var key = config.Keys.find(o => o.id === Code.substring(0, 4))?.AccessToken;
      var endPoint = config.GetInvoice;
      fetch(endPoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          appname: 'ljn',
          prgname: 'api-invoice',
          arguments:
            '-A' +
            Code.substring(0, 4) +
            key +
            ',-A' +
            Code.substring(0, 4) +
            ',-A' +
            Code.substring(4, 12) +
            ',-A' +
            Code.substring(12, 19),
        }),
        redirect: 'follow',
      })
        .then(response => response.text())
        .then(result => {
          var total = 0;
          const data = JSON.parse(result);
          if (!data.kessai) {
            history.replace(location1?.state?.from || { pathname: '/Error/' + data.message });
          }
          setpaymentTable(data.kessai);
          if (Object.keys(data).includes('kessai')) {
            data.kessai.forEach((paymentDetail: { title: string; price: string }) => {
              total = total + parseInt(paymentDetail.price);
            });
            info = info + 'Total: ' + total;

            //initial amazonpay button
            //initialAmazonPay(total);

            setTotalPaymentTable(total.toString());
            setTimeout(function() {
              GetPaymentLink(total.toString());
              GetPaymentLinkPaypay(total.toString());
            }, 100);
          } else {
            setPaymentInfo('Payment not ready. Please try again later');
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [info, clinicName]);

  return (
    <IntroContainer>
      <br className={classes.showPc} />
      <Typography variant="h5" className={classes.endTypo}>
        {clinicName}
      </Typography>
      <hr className={classes.showPc} />
      <Typography variant="h5" className={classes.gutterBottom}>
        {'お支払いを完了してください'}
      </Typography>
      <div className={classes.joinButtons}>
        <Button
          id="btn-pay-credit"
          variant="contained"
          type="submit"
          className={url.length != 0 ? classes.submitButton : classes.hidden}
          disabled={!url.length}
          onClick={PaymentHandler}
        >
          クレジットカード
        </Button>
        <Button
          variant="contained"
          type="submit"
          className={urlPaypay.length != 0 ? classes.paymentButton : classes.hidden}
          disabled={!urlPaypay.length}
          style={{ backgroundImage: `url(${BTN_PAY_PAY_PATH})` }}
          onClick={PaymentHandlerPaypay}
        ></Button>
        {/*<div id="AmazonPayButton" style={{ width: '100%', minHeight: '70px' }}></div>*/}
      </div>

      <Typography variant="h5" className={classes.paymentInformation}>
        {paymentInfo}
        <table className={classes.tableClass}>
          {paymentTable.map((row, index) => (
            <tr key={index}>
              <td className={classes.tdTitleClass}>
                <strong>{row['title']}</strong>
              </td>
              <td className={classes.tdPriceClass}>{FormatNumber(row['price'])}円</td>
            </tr>
          ))}
          <tr key="total">
            <td className={classes.tdTitleClass} style={{ backgroundColor: 'grey', color: 'white' }}>
              <strong>合計</strong>
            </td>
            <td className={classes.tdPriceClass}>
              <strong>{FormatNumber(totalPayment)}円</strong>
            </td>
          </tr>
        </table>
      </Typography>
    </IntroContainer>
  );
}
