import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import MainLoginPage from './components/LoginPage/MainLoginPage';
import WrongRoom from './components/LoginPage/WrongRoom';
import EndCall from './components/LoginPage/EndCall';
import EndPayment from './components/LoginPage/EndPayment';
import ErrorPage from './components/ErrorPage/ErrorPage';
import PaymentPage from './components/PaymentPage/PaymentPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatProvider>
        <App />
      </ChatProvider>
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <VideoApp />
            </PrivateRoute>
            {/* <PrivateRoute path="/room/:Type/:URLRoomName"> */}
            <PrivateRoute path="/room/:URLRoomName">
              <VideoApp />
            </PrivateRoute>
            <Route path="/login">
              <MainLoginPage />
            </Route>
            <Route exact path="/ErrorRoom">
              <WrongRoom />
            </Route>
            <Route exact path="/EndCall">
              <EndCall />
            </Route>
            <Route exact path="/EndPayment/:Code">
              <EndPayment />
            </Route>
            <Route path="/Error/:Error">
              <ErrorPage />
            </Route>
            <Route path="/payment/:Code">
              <PaymentPage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);
