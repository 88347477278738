import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
    height: '100%',
  },
  innerContainer: {
    display: 'flex',
    width: 'calc(100% - 15px)',
    height: 'calc(100% - 15px)',
    // border: '2px solid #707578',
    overflow: 'auto',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      // maxWidth: '400px',
      // border: '1px solid #707578',
    },
  },
  innerContainer2: {
    display: 'flex',
    width: 'calc(100% - 15px)',
    height: 'calc(100% - 15px)',
    // border: '2px solid #707578',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '92%',
      height: '92%',
      margin: 'auto',
      // maxWidth: '400px',
      // border: '1px solid #707578',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    backgroundSize: 'cover',
    // width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    height: '100%',
    padding: '0em 25%',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em 0',
    },
  },
  content2: {
    background: 'white',
    width: '100%',
    height: '100%',
    padding: '0em 25%',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();
  const [isCol, setIsCol] = useState(true);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < window.innerHeight) {
        setIsCol(true);
      } else {
        setIsCol(false);
      }
    }
    window.addEventListener('resize', handleResize);
  });
  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      setIsCol(true);
    } else {
      setIsCol(false);
    }
  }, []);
  return (
    <div className={classes.background}>
      {/* <TwilioLogo className={classes.twilioLogo} /> */}
      {user && location.pathname !== '/login' && <UserMenu />}
      <div className={classes.container}>
        {/* <div className={classes.swooshContainer}>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="96" viewBox="0 0 25 24">
              <path
                fill="#707578"
                fillRule="evenodd"
                d="M15.003 7c.665 0 1.206.542 1.206 1.207v1.349l3.58-1.789c.301-.15.652.04.704.358l.007.082v7.866c0 .365-.385.603-.711.44l-3.58-1.79v1.35c0 .623-.476 1.138-1.083 1.2l-.123.006H5.707c-.665 0-1.207-.542-1.207-1.206V8.207C4.5 7.542 5.042 7 5.707 7zm0 .983H5.707c-.122 0-.224.102-.224.224v7.866c0 .121.102.223.224.223h9.296c.122 0 .223-.102.223-.223V8.207c0-.122-.101-.224-.223-.224zm4.513 1.019l-3.307 1.654v2.968l3.307 1.653V9.002z"
              ></path>
            </svg>
          </span>
        </div> */}
        <div className={isCol ? classes.innerContainer : classes.innerContainer2}>
          <div className={isCol ? classes.content : classes.content2}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
