import React from 'react';
import StartRecordingIcon from '../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../icons/StopRecordingIcon';
import { styled, MenuItem, Typography } from '@material-ui/core';

import { useAppState } from '../../../state';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const START_RECORDING_TEXT = '録画開始';
export const STOP_RECORDING_TEXT = '録画停止';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function Record() {
  const { isFetching, updateRecordingRules } = useAppState();
  const isRecording = useIsRecording();
  const { room } = useVideoContext();

  return (
    <MenuItem
      disabled={isFetching}
      onClick={() => {
        if (isRecording) {
          updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
        } else {
          updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
        }
      }}
      data-cy-recording-button
    >
      <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
      <Typography variant="body1">{isRecording ? STOP_RECORDING_TEXT : START_RECORDING_TEXT}</Typography>
    </MenuItem>
  );
}
